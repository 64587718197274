import axios from "./customAxios";
export default {
    getArticles: () => axios({
        url: "/front/article/",
        method: "get"
    }),
    getArticle: (id) => axios({
        url: "/front/article/"+id+"/",
        method: "get"
    }),
    pumpeTime: (id) => axios({
        url: "/front/article/pumpe_time/"+id+"/",
        method: "get"
    })
}
import { createStore } from 'vuex'
import newDonation from "./newDonation"
import text from "./modules/text"

export default createStore({
    state: {
        search:false,
        monitor_search:1,
        orgInfo:{},
        projects:{},
        categorys_fundraise:{},
        // 點擊分類值 界面下端自動展開搜索更多，並查詢出該類型所有數據
        search_page_id:0,
        search_page_name:'',
        // 项目页内容 通过关键字搜寻出所有和该关键字有关的数据
        keyword:'',
        personal:{
            // 性别
            gender: "",
            // 請輸入身份證上的姓名
            name:"", 
            // 年龄
            age: '',
            // 邮件
            email: "",
            // 電話號碼
            mobile_phone: "",
            // 患者性别
            to_gender:"",
            // 患者是否是本人
            to_relation_type:"",
            // 患者名字
            patient_name:"",
            //患者所患疾病
            patient_disease:"",
            // 申請原因
            reason_application:"",
            // 詳細解釋
            explain_detail:"",
            // 請上傳生活照、現況、經濟情況相關證明文件
            other_files:[],
            // 請上傳病歷相關證明文件
            related_medical_files:[],
        },
        project:{
            // 預計開始時間
            // estimated_start_time:new Date(),
            // 項目時間
            // project_time:1,
            // 項目影片
            project_video:"",
             // 目標金額
            target_amount:0,
            // 籌款標題
            title:"",
            // 目標金額恆常項目
            td_constant_project: 'N',
            // 項目時間恆常項目
            // constant_project:'N',
            // 籌款内文
            introduction:"",
            // 圖片
            image:'',
        },
        file:'',
        agreementCheckList:[],
    },
    mutations: {
        setNavBarThemeToWhite(state) {
            state.navBarTheme = "white"
        },
        setNavBarThemeToTransparent(state) {
            state.navBarTheme = "transparent"
        },
        updateStore(){
            this.state.personal.gender = '';
            this.state.personal.name = '';
            this.state.personal.age = '';
            this.state.personal.email = '';
            this.state.personal.mobile_phone = '';
            this.state.personal.patient_disease = '';
            this.state.personal.reason_application = '';
            this.state.personal.to_gender = '';
            this.state.personal.patient_name = '';
            this.state.personal.explain_detail = '';
            this.state.personal.to_relation_type = '';
            this.state.personal.other_files = [],
            this.state.personal.related_medical_files = [],

            this.state.project.project_video = '';
            this.state.project.target_amount = 0;
            //this.state.project.estimated_start_time = new Date();
            this.state.project.project_time = 1;
            this.state.project.title = '';
            this.state.project.introduction = '';
            this.state.agreementCheckList = [];
            this.state.project.td_constant_project = 'N';
            this.state.project.constant_project = 'N';
            this.state.project.image = '';
        }
    },
    actions: {
    },
    modules: {
        newDonation,
        text
    }
})

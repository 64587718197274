import axios from "./customAxios";
export default {
    addFundraiseAuth: (data) => axios({
        url: "/front/fundraise_auth/",
        method: "post",
        data: data
    }),
    getFundraiseAuth: (data) => axios({
        url: "/front/fundraise_auth/" + data,
        method: "get"
    })
}
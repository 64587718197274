import axios from "./customAxios";
export default {
    addFundraiseBlacklist: (data) => axios({
        url: "/front/fundraise_blacklist/",
        method: "post",
        data: data
    }),
    getFundraiseBlacklist: (data) => axios({
        url: "/front/fundraise_blacklist/" + data,
        method: "get"
    })
}
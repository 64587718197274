<template>
    <button class="chat-button" @click="showChat = true">客服</button>
    <teleport to="body">
        <div class="chat-container" v-if="showChat">客服界面</div>
    </teleport>
</template>

<script>
export default {
    data() {
        return {
            showChat: false,
        };
    },
};
</script>

<style lang="scss" scoped>
.chat-button {
    width: 20px;
    height: 60px;
    background-color: $white;
    position: fixed;
    right: 0;
    bottom: 10vh;
}
.chat-container {
    width: 300px;
    height: 600px;
    background-color: $white;
    position: fixed;
    bottom: 16px;
    right: 16px;
}
</style>
export default {
    namespaced: true,
    state: {
        currentStep: 1
    },
    mutations: {
        next(state) {
            state.currentStep = Math.min(state.currentStep + 1, 3)
        },
        previous(state) {
            state.currentStep = Math.max(state.currentStep - 1, 1)
        }
    }
}
import axios from "./customAxios";
export default {
    getFundraises: (params) => axios({
        url: "/front/fundraise/"+params,
        method: "get"
    }),
    getFundraise: (id) => axios({
        url: "/front/fundraise/"+id+"/",
        method: "get"
    }),
    getCategorysFundraise: (params) => axios({
        url: "/front/fundraise/"+params,
        method: "get"
    }),
    getFundraisePage: (params) => axios({
        url: "/front/fundraise/"+params,
        method: "get"
    }),
    getFundraiseDetails: (params) => axios({
        url: "/front/fundraise/get/"+params,
        method: "get"
    }),
    pumpeTime: (id) => axios({
        url: "/front/fundraise/pumpe_time/"+id+"/",
        method: "get"
    })
}
import axios from "./customAxios";
export default {
    postPayment: (formData) => axios({
        url: "/front/paypal/payment/",
        method: "post",
        headers:{'Content-Type': 'application/x-www-form-urlencoded'},
        data:formData
    }),
    payments: (id,payment_id,payer_id) => axios({
        url: "/front/paypal/pay/"+id+"/?paymentId="+payment_id+'&PayerID='+payer_id,
        headers:{'Content-Type': 'application/json'},
        method: "get"
    }),
    getPaypals: (params) => axios({
        url: "/front/paypal/"+params,
        method: "get"
    }),
}
<template>
    <footer>
        <router-link to="/clause">條款及細則</router-link>
        <router-link :to="{ name: 'aboutUs' }">關於我們</router-link>
        <span>iMeddy©2021</span>
    </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
footer {
    height: 48px;
    padding: 16px;
    line-height: 16px;
    @include fr-c-c;
    background-color: $green-500;
    a,
    a:active,
    a:visited {
        display: inline-block;
        color: $white;
        text-decoration: none;
    }
    a:last-of-type {
        border-left: 1px solid $white;
        border-right: 1px solid $white;
    }
    a,
    span {
        color: $white;
        padding: 0 8px;
        font-size: 14px;
    }
}
</style>
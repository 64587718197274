import example from "./example";
import article from "./article";
import dictData from "./dict/data";
import fundraise from "./fundraise";
import category from "./category";
import paypal from "./paypal";
import img from "./img";
import verification from "./verification";
import fundraiseAuth from "./fundraiseAuth";
import fundraiseBlacklist from "./fundraiseBlacklist";
import fundraiseDynamic from "./fundraiseDynamic";

export default {
    example,
    article,
    dictData,
    fundraise,
    category,
    img,
    paypal,
    verification,
    fundraiseAuth,
    fundraiseBlacklist,
    fundraiseDynamic
}
import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

const routes = [
    {
        path: "/",
        name: "index",
        component: () => import("../views/index.vue"),
        meta: {
            navTheme: "transparent"
        }
    },
    {
        path: "/blacklist",
        name: "blacklist",
        component: () => import("../views/blacklist/blacklist.vue"),
        meta: {
            navTheme: "white"
        }
    },
    {
        path: "/projects",
        name: "projects",
        component: () => import("../views/projects/projects.vue"),
        meta: {
            navTheme: "white"
        }
    },
    {
        path: "/clause",
        name: "clause",
        component: () => import("../views/clause.vue"),
        meta: {
            navTheme: "white"
        }
    },
    {
        path: "/project/:id",
        name: "project",
        component: () => import("../views/project/project.vue"),
        meta: {
            navTheme: "white"
        },
        redirect: { name: "projectContent" },
        children: [
            {
                path: "content",
                name: "projectContent",
                component: () => import("../views/project/pages/content.vue")
            },
            {
                path: "info",
                name: "projectInfo",
                component: () => import("../views/project/pages/info.vue")
            },
            {
                path: "activities",
                name: "projectActivities",
                component: () => import("../views/project/pages/activities.vue")
            },
            {
                path: "provement",
                name: "projectProvement",
                component: () => import("../views/project/pages/provement.vue")
            },
            {
                path: "personal_verification",
                name: "projectPersonalVerification",
                component: () => import("../views/project/pages/personalVerification.vue")
            }
        ]
    },
    {
        path: "/new-donation",
        name: "newDonation",
        component: () => import("../views/newDonation/newDonation.vue"),
        meta: {
            navTheme: "transparent"
        },
        redirect: { name: "newDonationStep1" },
        children: [
            {
                path: "step-1",
                name: "newDonationStep1",
                component: () => import("../views/newDonation/step1.vue")
            },
            {
                path: "step-2",
                name: "newDonationStep2",
                component: () => import("../views/newDonation/step2.vue")
            },
            {
                path: "step-3",
                name: "newDonationStep3",
                component: () => import("../views/newDonation/step3.vue")
            },
        ]
    },
    {
        path: "/about-us",
        name: "aboutUs",
        component: () => import("../views/aboutUs/aboutUs.vue"),
        meta: {
            navTheme: "white"
        }
    },
    {
        path: "/news/:id",
        name: "news",
        component: () => import("../views/news.vue"),
        meta: {
            navTheme: "white"
        }
    },
    {
        path: "/donate/:id/:type",
        name: "donate",
        component: () => import("../views/donate.vue"),
        meta: {
            navTheme: "white"
        }
    },
    {
        path: "/donate_success/:id",
        name: "donateSuccess",
        component: () => import("../views/donateSuccess.vue"),
        meta: {
            navTheme: "white"
        }
    },
    {
        path: "/agencies_to_verify/:id",
        name: "agenciesToVerify",
        component: () => import("../views/agenciesToVerify.vue"),
        meta: {
            navTheme: "white"
        }
    },
    {
        path: "/personal_verification/:id",
        name: "personalVerification",
        component: () => import("../views/personalVerification.vue"),
        meta: {
            navTheme: "white"
        }
    }
]

const router = createRouter({
    linkActiveClass: "active",
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to) => {
    switch (to.meta.navTheme) {
        case "white": store.commit("setNavBarThemeToWhite"); break;
        case "transparent": store.commit("setNavBarThemeToTransparent"); break;
    }
})

export default router

<template>
    <nav-bar :theme="theme"></nav-bar>
    <router-view></router-view>
    <custom-footer></custom-footer>
    <customer-service></customer-service>
</template>

<script>
// import api from "./api/index.js";
import NavBar from "./components/navBar.vue";
import CustomFooter from "./components/customFooter.vue";
import CustomerService from "./components/customerService.vue";
export default {
    components: { NavBar, CustomFooter, CustomerService},
    computed: {
        theme() {
            return this.$store.state.navBarTheme;
        },
    },
    created() {
        // api.example.test(3).then((res) => {
        //     console.log(res.data)
        //     请求成功时的操作。。。
        // }).catch(error => {
        //     console.error(error)
        //     请求失败时的操作。。。
        // }).finally(() => {
        //     console.log("finished")
        //     请求结束时的操作（不论成败都会调用）
        // });
    },
};
</script>

<style lang="scss">
body {
    overflow-x: hidden;
}
* {
    color: $gray-900;
}
</style>

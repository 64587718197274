import axios from "./customAxios";
export default {
    getFundraiseDynamic: (data) => axios({
        url: "/front/fundraise_dynamic/" + data,
        method: "get"
    }),
    getFundraiseDynamics: (params) => axios({
        url: "/front/fundraise_dynamic/"+params,
        method: "get"
    }),
}
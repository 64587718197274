<template>
    <label class="custom-radio-container" :class="{ 'block-display': isBlock }">
        <input
            @change="$emit('update:selected', value)"
            type="radio"
            :value="value"
            :checked="selected === value"
            class="custom-radio-input"
        />
        <div class="custom-radio-box" v-if="!isBlock"></div>
        <slot v-if="!isBlock"></slot>
        <div class="custom-block-container" v-if="isBlock">
            <slot></slot>
        </div>
    </label>
</template>

<script>
/**
 * v-model:selected: 用于数据双向绑定
 * value: radio的值
 * type: radio的显示样式，有两种："normal" - 左边圆圈右边内容，"block" - 黑色方框，选中变红
 */
export default {
    emits: ["update:selected"],
    props: {
        selected: {
            required: true,
        },
        value: {
            required: true,
        },
        type: {
            type: String,
            required: false,
            default: "normal",
            validator: (val) => {
                return ["normal", "block"].includes(val);
            },
        },
    },
    computed: {
        isBlock() {
            return this.type === "block";
        },
    },
};
</script>

<style lang="scss">
.custom-radio-input {
    display: none;
}
.custom-radio-box {
    width: 20px;
    height: 20px;
    border: 1px solid $gray-300;
    border-radius: 5px;
    margin-right: 8px;
    color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}
.custom-block-container {
    @include fr-c-c;
    color: $gray-900;
    border: 1px solid $gray-900;
    height: 40px;
    width: 100%;
}
input:checked + .custom-radio-box {
    background-image: url("../../assets/img/tick.png");
    background-position: center;
    border: none;
    border-radius: 0;
}
input:checked + .custom-block-container {
    color: $red-500;
    border-color: $red-500;
}
.custom-radio-container {
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    &.block-display {
        margin-right: 0;
    }
}
</style>
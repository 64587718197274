import axios from "./customAxios";
export default {
    getImg: (formData) => axios({
        url: "/front/savefile/",
        method: "post",
        header: {
            "Content-Type": "multipart/form-data"
        },
        data:formData
    }),
}
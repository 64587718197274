<template>
    <label class="custom-check-box-container">
        <input
            type="checkbox"
            @change="$emit('update:modelValue', localValue)"
            v-model="localValue"
            :value="value"
            class="custom-check-box-input"
        />
        <div class="fake-custom-check-box"></div>
        <slot></slot>
    </label>
</template>

<script>
/**
 * v-model: 用于数据双向绑定
 * value: 用于为该选项分配一个值（v-model时绑定的对象数组将会push进这个值）
 */
export default {
    emits: ["update:modelValue"],
    props: ["modelValue", "value"],
    data() {
        return {
            localValue: this.modelValue,
        };
    },
    watch: {
        modelValue() {
            this.localValue = this.modelValue;
        },
    },
};
</script>

<style lang="scss">
.custom-check-box-container {
    cursor: pointer;
    @include fr-c-s;
    .custom-check-box-input {
        display: none;
    }
    .fake-custom-check-box {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        border: 1px solid $gray-300;
        border-radius: 5px;
        margin-right: 8px;
        color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    input:checked + .fake-custom-check-box {
        background-image: url("../../assets/img/tick.png");
        background-position: center;
        border: none;
        border-radius: 0;
    }
}
</style>
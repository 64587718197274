import qs from "qs";
import axios from "axios";

let baseURL;
switch (process.env.NODE_ENV) {
    case "production":
        baseURL = process.env.VUE_APP_API_SERVER;
        break;
    case "development":
        baseURL = process.env.VUE_APP_API_SERVER;
        break;
}
const customAxios = axios.create({
    baseURL,
    paramsSerializer: params => {
        return qs.stringify(params);
    }
})

customAxios.interceptors.request.use((config) => {
    // 为所有请求添加token
    // TODO: 还没有具体确定怎么获取/存储token
    // let token = "";
    // if (!config.params) config.params = { token }
    // else config.params.accessToken = token

    // 驼峰转下划线的方法
    function underline(data) {
        if (typeof data != 'object' || !data) return data
        if (Array.isArray(data)) {
            return data.map(item => underline(item))
        }

        const newData = {}
        for (let key in data) {
            let newKey = key.replace(/([A-Z])/g, (_, m) => `_${m.toLowerCase()}`)
            newData[newKey] = underline(data[key])
        }
        return newData
    }
    // 将所有请求的url参数改为下划线形式
    config.params = underline(config.params)
    // 如果是put, post或patch则对data也进行下划线形式转换
    if (["post", "put", "patch"].includes(config.method.toLowerCase()))
        config.data = underline(config.data)

    return config;
})

customAxios.interceptors.response.use((response) => {
    // 下划线转驼峰的方法
    function camel(data) {
        if (typeof data != 'object' || !data) return data
        if (Array.isArray(data)) {
            return data.map(item => camel(item))
        }

        const newData = {}
        for (let key in data) {
            let newKey = key.replace(/_([a-z])/g, (p, m) => m.toUpperCase())
            newData[newKey] = camel(data[key])
        }
        return newData
    }
    // 将response中的所有数据转为驼峰命名
    response.data = camel(response.data)

    return response.data;
})

export default customAxios;
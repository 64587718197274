<template>
    <div v-loading="loading"  element-loading-text="加載内容中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="custom-file-upload" @click="$refs.uploader.click()">
            <img
                src="../../assets/img/upload.png"
                alt=""
                class="custom-file-upload-icon"
            />
            <input
                type="file"
                class="custom-file-upload-input"
                @change="test"
                ref="uploader"
            />
            <div class="toast" v-show="toastShow">
                {{toastText}}
            </div>
        </div>
    </div>
</template>

<script>
/**
 * 推荐之后把上传文件的api在这里引用，然后返回一个filePath，这样其他地方调用起来方便很多
 */
import api from "@/api";
export default {
    data(){
        return {
            toastShow: false,
            toastText: '',
            loading:false,
        };
    },
    methods: {
        toast(str) {
            let v = this
            v.toastText = str
            v.toastShow = true
            setTimeout(function() {
                v.toastShow = false
            }, 1500)
        },
        test(e) {
            let file = e.target.files[0];
            let types = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
            let isUploadFile = types.includes(file['type']);
            
            let fileSizeFlag = file.size / 1024 / 1024  < 20 ? true : false;
            if (! isUploadFile) {
                this.$message.error('上傳文件只能是image、pdf格式')
                return false;
            }

            if(! fileSizeFlag) {
                this.$message.error('上傳文件大小不能超過20m');
                return false;
            }else{
                this.loading = true;
                let formData = new FormData();
                formData.append("file", file);
                formData.append("source", '籌款上傳');

                let that = this;
                that.$axios({
                    url:process.env.VUE_APP_API_SERVER+'/front/savefile/',
                    method: 'post',
                    data:formData,
                    header: {
                        "Content-Type": "multipart/form-data"
                    }          
                }).then(function(res){
                    that.$store.state.project.image = res.data.data.file_url;
                    that.$store.state.file = res.data.data.file;
                    that.loading = false;
                }).catch(function(err){
                    this.$message.error('上傳文件失敗');
                    that.loading = false;
                })
            }
        },
    },
};
</script>

<style lang="scss">
.toast {
			position: fixed;
			z-index: 2000;
			left: 50%;
			top: 45%;
			transition: all .5s;
			-webkit-transform: translateX(-50%) translateY(-50%);
			-moz-transform: translateX(-50%) translateY(-50%);
			-ms-transform: translateX(-50%) translateY(-50%);
			-o-transform: translateX(-50%) translateY(-50%);
			transform: translateX(-50%) translateY(-50%);
			text-align: center;
			border-radius: 5px;
			color: #FFF;
			background: rgba(17, 17, 17, 0.7);
			height: 45px;
			line-height: 45px;
			padding: 0 15px;
			max-width: 300px;
		}
.custom-file-upload {
    cursor: pointer;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    border: 1px solid $green-300;
    .custom-file-upload-input {
        display: none;
    }
    .custom-file-upload-icon {
        position: absolute;
        width: 20px;
        height: 20px;
        top: calc(50% - 10px);
        left: calc(50% - 10px);
    }
}
</style>
<template>
    <nav :class="{ 'trans-theme': !isWhite, 'white-theme': isWhite }">
        <div class="nav_content">
            <div class="img-container">
                <router-link :to="{ name: 'index' }">
                    <img src="../assets/img/iMeddy-logo.png" alt="iMeddy's Logo" />
                </router-link>
            </div>
            <div class="links-container">
                <router-link :to="{ name: 'index' }">首頁</router-link>
                <router-link :to="{ name: 'projects' }">項目</router-link>
                <router-link :to="{ name: 'blacklist' }">黑名單</router-link>
                <router-link @click="f1" :to="{ name: 'newDonation' }">建立籌款</router-link>
                <router-link :to="{ name: 'aboutUs' }">關於我們</router-link>
                <button class="burger-menu">
                    <i class="icon-menu"></i>
                </button>
            </div>
        </div>
        
    </nav>
</template>

<script>
export default {
    props: {
        theme: {
            type: String,
            required: false,
            default: "white",
            validator: (v) => {
                return ["white", "transparent"].includes(v);
            },
        },
    },
    computed: {
        isWhite() {
            return this.theme === "white";
        },
    },
    methods:{
        f1(){
            // 點擊衆籌時，把衆籌下面的内容全部清空
            this.$store.commit("updateStore");
        }
    }
};
</script>

<style lang="scss" scoped>
a{
 text-decoration: none;
}
nav.white-theme {
    background-color: $white;
    --theme-color: #{$green-500};
    box-shadow: 0 0 8px rgba($color: $gray-900, $alpha: 0.2);
}
nav.trans-theme {
    background-color: transparent;
    --theme-color: #{$white};
}
.nav_content {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
nav {
    width: 100vw;
    padding: 10px 16px;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    @include fr-b-c;
    .img-container {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: $white;
        padding: 10px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .links-container {
        @include fr-b-c;
        a,
        a:hover,
        a:visited,
        a:active {
            color: var(--theme-color);
            text-decoration: none;
        }
        a {
            display: block;
            margin: 0 16px;
            padding: 4px 0;
            font-size: 18px;
            border-bottom: 2px solid transparent;
            &.active {
                border-bottom-color: var(--theme-color);
                font-weight: 600;
            }
        }
        .burger-menu {
            display: none;
            color: var(--theme-color);
            font-size: 20px;
        }
    }
}
</style>
import store from '@/store/index';
import QS from "qs";
import axios from 'axios';


if (process.env.NODE_ENV == 'development') {    
    axios.defaults.baseURL = process.env.VUE_APP_API_SERVER;
}else if (process.env.NODE_ENV == 'debug') {
    axios.defaults.baseURL = process.env.VUE_APP_API_SERVER;
}else if (process.env.NODE_ENV == 'production') {
    axios.defaults.baseURL = process.env.VUE_APP_API_SERVER;
}

// 创建axios实例
var instance = axios.create({    timeout: 1000 * 12});
// 设置post请求头
// instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
instance.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';

/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function get(url, params){    
    return new Promise((resolve, reject) =>{        
        axios.get(url, {            
            params: params        
        })        
        .then(res => {            
            resolve(res.data);        
        })        
        .catch(err => {            
            reject(err.data)        
        })    
    });
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params) {    
    return new Promise((resolve, reject) => {         
        axios.post(url, params)        
        .then(res => {            
            resolve(res.data);        
        })        
        .catch(err => {            
            reject(err.data)        
        })    
    });
}
<template>
    <div
        class="custom-selection-container"
        @click="onClickSelection"
        ref="dropdown"
    >
        <span v-if="selectedOption">{{ selectedOption.label }}</span>
        <span v-else>{{ placeholder }}</span>
        <i class="icon-icon_down_arrow" :class="{ rotated: showDropdown }"></i>
        <div
            class="custom-selection-dropdown"
            :class="{ active: showDropdown }"
        >
            <div class="custom-selection-no-data" v-if="!hasData">暫無數據</div>
            <div
                class="custom-selection-option"
                :class="{ selected: selectedOption === option }"
                v-for="option in options"
                :key="option.value"
                @click.stop="doSelection(option.value)"
            >
                {{ option.label }}
            </div>
        </div>
    </div>
</template>

<script>
/**
 * v-model：同原生v-model，与options的value绑定
 * placeholder: 同原生placeholder
 * options: select内的options，为一个array，需要有"label"和"value"
 * 两个属性，"label"用于展示，"value"用于数据交互
 */
let that;
function callback(e) {
    if (!e.path.includes(that.$refs.dropdown)) that.closeDropdown();
}
export default {
    data() {
        return {
            showDropdown: false,
        };
    },
    emits: ["update:modelValue"],
    props: {
        options: {
            type: Array,
            required: false,
            default: () => [],
            validator: (val) => {
                for (let e of val) {
                    if (
                        Object.keys(e).includes("label") &&
                        Object.keys(e).includes("value")
                    )
                        continue;
                    else return false;
                }
                return true;
            },
        },
        placeholder: {
            type: String,
            required: false,
            default: "",
        },
        modelValue: {
            required: true,
        },
    },
    computed: {
        hasData() {
            return this.options.length >= 1;
        },
        selectedOption() {
            for (let option of this.options) {
                if (option.value === this.modelValue) return option;
            }
            return null;
        },
    },
    methods: {
        onClickSelection() {
            if (!this.showDropdown) {
                document.addEventListener("click", callback);
                this.showDropdown = true;
            } else {
                this.closeDropdown();
            }
        },
        doSelection(value) {
            this.$emit("update:modelValue", value);
            this.closeDropdown();
        },
        closeDropdown() {
            document.removeEventListener("click", callback);
            this.showDropdown = false;
        },
    },
    created() {
        that = this;
    },
};
</script>

<style lang="scss">
.custom-selection-container {
    border: 1px solid $green-300;
    width: 100%;
    padding: 12px;
    font-size: 16px;
    margin: 8px 0;
    cursor: pointer;
    position: relative;
    overflow: visible;
    @include fr-b-c;
    i {
        transition: all 0.1s ease;
        &.rotated {
            transform: rotateZ(180deg);
        }
    }
}
.custom-selection-dropdown {
    overflow: auto;
    width: calc(100% + 2px);
    height: auto;
    max-height: 150px;
    position: absolute;
    background-color: $white;
    left: -1px;
    top: calc(100% + 8px);
    transform-origin: top center;
    transform: scaleY(0);
    transition: all 0.1s ease;
    @include fc-s-st;
    border-radius: 4px;
    border: 1px solid $green-300;
    &.active {
        transform: scaleY(1);
    }
    .custom-selection-no-data {
        text-align: center;
        padding: 8px;
        color: $gray-300;
    }
    .custom-selection-option {
        text-align: center;
        padding: 8px;
        &:hover {
            background-color: $green-500;
            color: $white;
        }
        &.selected {
            font-weight: 600;
        }
    }
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        width: 4px;
        border-radius: 2px;
        background-color: $green-500;
    }
    &::-webkit-scrollbar-track {
        width: 4px;
        background-color: transparent;
    }
}
</style>
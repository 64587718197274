<template>
    <textarea
        @input="$emit('update:modelValue', localValue)"
        v-model="localValue"
        class="custom-textarea"
    ></textarea>
</template>

<script>
/**
 * v-model: 用于双向绑定数据
 * 其他原生属性亦可使用
 */
export default {
    emits: ["update:modelValue"],
    props: ["modelValue"],
    data() {
        return {
            localValue: this.modelValue,
        };
    },
    watch: {
        modelValue: function () {
            this.localValue = this.modelValue;
        },
    },
};
</script>

<style lang="scss">
.custom-textarea {
    border: 1px solid $green-300;
    resize: none;
    width: 100%;
    margin: 8px 0;
    font-size: 16px;
    padding: 8px;
    outline: none;
    background-color: $white;
}
</style>
<template>
    <input
        type="text"
        @input="$emit('update:modelValue', localValue)"
        v-model="localValue"
        class="custom-input"
        @blur="email_blur"
    />
</template>

<script>
/**
 * v-model: 双向绑定
 * 其他原生属性都可以用在这上面
 */
export default {
    data() {
        return { localValue: this.modelValue };
    },
    emits: ["update:modelValue"],
    props: ["modelValue"],
    watch: {
        modelValue() {
            this.localValue = this.modelValue;
        },
    },
    methods:{
    }
};
</script>

<style lang="scss">
.custom-input {
    width: 100%;
    border: 1px solid $green-300;
    outline: none;
    padding: 8px;
    margin: 8px 0;
    font-size: 16px;
    background-color: $white;
}
</style>
import axios from "./customAxios";
export default {
    getCategorys: () => axios({
        url: "/front/category/",
        method: "get"
    }),
    getCategorysExplore: (params) => axios({
        url: "/front/category/"+params,
        method: "get"
    })
}
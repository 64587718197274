/**   
 * api接口统一管理
 */
import { get, post } from './http'

export const apiLogin = p => post('v1/users/login', p);

export const apiDoctorWeekly = p => get('v1/doctor-weekly/1', p);

export const apiFundraise = p => post('front/fundraise/', p);

export const apiRequest = p => post('front/fundraise/?pageNum=1&pageSize=5&state=50010/', p);

export const img = p => post('front/savefile/', p);
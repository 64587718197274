<template>
    <button class="cf-button" :class="buttonClass">
        <slot></slot>
    </button>
</template>

<script>
/**
 * disabled: 按钮不可用
 * radius: [none, xs, sm, md, lg, xl, half, full]
 * 对应大小：[无，超小，小，中，大，超大，半圆，圆形/椭圆形]
 */
export default {
    props: {
        radius: {
            type: String,
            required: false,
            default: "half",
            validator: (value) => {
                return [
                    "none",
                    "xs",
                    "sm",
                    "md",
                    "lg",
                    "xl",
                    "half",
                    "full",
                ].includes(value);
            },
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    computed: {
        buttonClass() {
            return this.radius;
        },
    },
};
</script>

<style lang="scss">
.cf-button {
    cursor: pointer;
    background-color: $red-500;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: $white;
    padding: 0 16px;
    &.full {
        border-radius: 99999px;
        padding: 0;
    }
    &.half {
        border-radius: 20px;
    }
    &.xl {
        border-radius: 16px;
    }
    &.lg {
        border-radius: 12px;
    }
    &.md {
        border-radius: 10px;
    }
    &.sm {
        border-radius: 8px;
    }
    &.xs {
        border-radius: 4px;
    }
    &.none {
        border-radius: 0;
    }
    &:disabled {
        opacity: 0.5;
    }
}
</style>
import { createApp } from 'vue'
import VCalendar from 'v-calendar';
import App from './App.vue'
import axios from 'axios';
// 插件
import router from './router'
import store from './store'
// 全局组件
import PrimaryButton from './components/UI/primaryButton.vue'
import CustomRadio from "./components/UI/customRadio.vue";
import CustomInput from "./components/UI/customInput.vue";
import CustomInputNumber from "./components/UI/customInputNumber.vue";
import CustomTextarea from "./components/UI/customTextarea.vue";
import CustomFileUpload from "./components/UI/customFileUpload.vue";
import CustomFileUploadSingle from "./components/UI/customFileUploadSingle.vue";
import CustomFileUploads from "./components/UI/customFileUploads.vue";
import CustomCheckBox from "./components/UI/customCheckBox.vue";
import CustomSelection from "./components/UI/customSelection.vue";
import 'element-plus/lib/theme-chalk/index.css'
import ElementPlus from 'element-plus'


import "swiper/dist/css/swiper.min.css";
// 全局混入
import navBarMutations from './mixins/navBarMutations'

// CSS
import "./assets/css/index.css"

const app = createApp(App);
app.config.globalProperties.$axios=axios

// 插件
app.use(store);
app.use(ElementPlus);
app.use(router);
app.use(VCalendar);
// 全局组件注册
app.component("PrimaryButton", PrimaryButton);
app.component("CustomRadio", CustomRadio);
app.component("CustomInput", CustomInput);
app.component("CustomTextarea", CustomTextarea);
app.component("CustomFileUpload", CustomFileUpload);
app.component("CustomFileUploadSingle", CustomFileUploadSingle);
app.component("CustomFileUploads", CustomFileUploads);
app.component("CustomCheckBox", CustomCheckBox);
app.component("CustomSelection", CustomSelection);
app.component("CustomInputNumber", CustomInputNumber);
// 全局混入
app.mixin(navBarMutations)
// 挂载
app.mount('#app');
